import { Fragment, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { dividerState, textColorState } from "@/lib/store";
import Link from "next/link";
import MenuPopup from "../menu-popup/MenuPopup";
import { useRecoilValue } from "recoil";
import { useState } from "react";
import { createPortal } from "react-dom";
import styles from "./Menu.module.scss";
import { Chip, Divider, Typography, useMediaQuery } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useRouter } from "next/router";
import { classNames } from "@/lib/utils";

const COMING_SOON = "/coming-soon";

const menuList = [
    {
        icon: "fak fa-markets-overview",
        text: "Markets Overview",
        link: "/markets-overview",
    },
    {
        link: "/market-sentiment",
        icon: "fak fa-data-sentiment",
        text: "Market Sentiment",
    },
    {
        icon: "fak fa-stock-data-sets",
        text: "Stock Data Sets",
        submenu: [
            {
                icon: "fal fa-chart-line-down",
                href: "/short-interest",
                text: "Short Interest",
            },
            {
                icon: "fak fa-stock-options",
                href: "/options",
                text: "Options",
            },
            {
                icon: "fal fa-users",
                href: "/insiders",
                text: "Insiders",
            },
            {
                icon: "fal fa-user-tie",
                href: "/analysts",
                text: "Analysts",
            },
            {
                icon: "fal fa-newspaper",
                href: "/news",
                text: "News",
            },
            {
                icon: "fal fa-calendar-alt",
                href: "/events",
                text: "Company Events",
            },

            {
                icon: "fal fa-list-ol",
                href: "/holdings",
                text: "Holdings",
            },
            {
                icon: "fal fa-chart-pie-alt",
                href: "/dividend",
                text: "Dividends",
            },
            {
                icon: "fak fa-ipos-deals",
                href: "/ipo-deals",
                text: "IPOs & Deals",
            },
            {
                icon: "fal fa-split",
                text: "Stock Splits",
                href: "/stock-splits",
            },
            {
                icon: "fal fa-balance-scale",
                href: "/index-rebalance",
                text: "Index Rebalance",
            },
            {
                icon: "fak fa-credit-default-swaps",
                href: "/cds",
                text: "Credit Default Swaps",
            },
            {
                href: "/lock-ups",
                icon: "fak fa-lock-solid-2",
                text: "Lock Ups",
            },
            {
                href: "/us-gov-trades",
                icon: "fak fa-building-columns-regular",
                text: "US Government Trades",
            },
        ],
    },

    {
        link: "/money-flow",
        icon: "fak fa-passive-money-flow",
        text: "Money Flow",
    },

    {
        link: "/index-rebalance",
        icon: "fal fa-balance-scale",
        text: "Index Rebalance",
    },

    {
        link: "/stock-movers",
        icon: "fak fa-charts",
        text: "Stock Movers",
    },

    {
        link: "/stock-stamps",
        icon: "fak fa-stock-stamps",
        text: "Stock Stamps",
    },

    {
        link: "/portfolio",
        icon: "fak fa-portfolio",
        text: "My Portfolio",
    },

    {
        link: "/my-universe",
        icon: "fak fa-my-universe",
        text: "My Stocks",
    },
    {
        icon: "fal fa-newspaper",
        link: "/news",
        text: "News",
    },
    {
        icon: "fal fa-calendar-alt",
        text: "Events",
        submenu: [
            {
                icon: "fal fa-calendar-alt",
                text: "Company Events",
                href: "/events",
            },
            {
                icon: "fal fa-globe-europe",
                text: "Macro Events",
                href: "/macro",
            },
            {
                icon: "fak fa-stock-trading-signals",
                text: "Earnings HeatMap",
                href: "/earnings/heatmap",
            },
            {
                icon: "fal fa-split",
                text: "Stock Splits",
                href: "/stock-splits",
            },
        ],
    },
    {
        icon: "fal fa-list-ol",
        text: "Ownership",
        submenu: [
            {
                href: "/holdings",
                icon: "fal fa-list-ol",
                text: "Holdings",
            },
            {
                href: "/insiders",
                icon: "fal fa-users",
                text: "Insiders",
            },
            {
                href: "/lock-ups",
                icon: "fak fa-lock-solid-2",
                text: "Lock Ups",
            },
            {
                href: "/us-gov-trades",
                icon: "fak fa-building-columns-regular",
                text: "US Government Trades",
            },
        ],
    },
    {
        link: "/trading-signals",
        icon: "fak fa-trading-signals",
        text: "Trading Signals",
    },

    {
        link: "/stock-scores",
        icon: "fak fa-stock-scores",
        text: "Stock Scores",
    },

    {
        link: "/sector-scores",
        icon: "fak fa-ranking-star",
        text: "Sector Scores",
    },
    {
        link: "/cds",
        icon: "fak fa-credit-default-swaps",
        text: "Credit Default Swaps",
    },
    {
        link: "/etfs",
        icon: "fak fa-etfs",
        text: "ETFs",
        submenu: [
            {
                icon: "fak fa-etfs",
                href: "/etfs",
                text: "ETF Overview",
            },
            {
                icon: "fak fa-etfs",
                href: "/money-flow",
                text: "ETF Fund Flow",
            },
            {
                icon: "fak fa-etfs",
                href: COMING_SOON,
                text: "ETF Shorts",
            },
        ],
    },

    {
        icon: "fak fa-screening",
        text: "Charts & Screening",
        submenu: [
            {
                icon: "fak fa-stock-trading-signals",
                href: "/charts",
                text: "Charts",
            },
            {
                icon: "fak fa-screening",
                href: "/screening",
                text: "Screening",
            },

            {
                icon: "fak fa-stock-trading-signals",
                href: "/heatmap",
                text: "HeatMaps",
            },
            {
                icon: "fal fa-chart-line-down",
                text: "Short Screener",
                href: "/short-interest?tab=short_extremes",
            },
            {
                icon: "fal fa-chart-pie-alt",
                href: "/dividend",
                text: "Dividends Screener",
            },
        ],
    },
    {
        link: "",
        icon: "",
        text: "Popular",
    },
    {
        icon: "fak fa-screening",
        link: "/screening",
        text: "Screening",
    },
    {
        icon: "fal fa-chart-line-down",
        text: "Short Interest",
        submenu: [
            {
                icon: "fal fa-chart-line-down",
                text: "Short Extremes",
                href: "/short-interest?tab=short_extremes",
            },
            {
                icon: "fal fa-chart-line-down",
                text: "Short Changes",
                href: "/short-interest?tab=short_changes",
            },
            {
                icon: "fal fa-chart-line-down",
                text: "Short HeatMap",
                href: "/short-interest?tab=heatmap",
            },
            {
                icon: "fal fa-chart-line-down",
                text: "Sector Averages",
                href: "/short-interest?tab=sector_averages",
            },
            {
                icon: "fal fa-chart-line-down",
                text: "Index Averages",
                href: "/short-interest?tab=index_averages",
            },
            {
                icon: "fal fa-chart-line-down",
                text: "European Short Flags",
                href: "/short-interest?tab=european_short_reports",
            },
            {
                icon: "fal fa-chart-line-down",
                text: "European Short Holders",
                href: "/short-interest?tab=european_short_holders",
            },
        ],
    },

    {
        icon: "fak fa-stock-options",
        text: "Options",
        submenu: [
            {
                icon: "fak fa-stock-options",
                text: "Interesting Options Flow",
                href: "/options?tab=interesting-options-flow",
            },
            {
                icon: "fak fa-stock-options",
                text: "Options Flow Sentiment",
                href: "/options?tab=option-flow-sentiment",
            },
            {
                icon: "fak fa-stock-options",
                text: "Options Extremes",
                href: "/coming-soon",
            },
        ],
    },
    {
        icon: "fal fa-users",
        text: "Insiders",
        link: "/insiders",
    },

    {
        icon: "fal fa-user-tie",
        text: "Analysts",
        link: "/analysts",
    },
    {
        icon: "fak fa-stock-trading-signals",
        text: "HeatMaps",
        link: "/heatmap",
    },
];

export const MenuListItems = ({ isIconOnly = false }: { isIconOnly?: boolean }) => {
    const [selectedItem, setSelectedItem] = useState(undefined);
    const [minMenuHovered, setMinMenuHovered] = useState(false);
    const isnotMobile = useMediaQuery("(min-width: 768px)");

    const router = useRouter();

    const textColor = useRecoilValue(textColorState);
    const dividerColor = useRecoilValue(dividerState);

    const handleHover = (key: number, isMinMenu?: boolean) => {
        setSelectedItem(key);
        setMinMenuHovered(isMinMenu);
    };

    return (
        <>
            <div className={styles.MenuOverlay} onMouseEnter={() => setSelectedItem(undefined)}></div>
            <List sx={{ textAlign: "center", paddingY: 0.5 }} className={styles.list} id="list">
                {menuList.map((item, index, to) =>
                    !isIconOnly ? (
                        item.text === "Popular" ? (
                            <Fragment key={index}>
                                <Divider
                                    sx={{
                                        "&::before, &::after": {
                                            borderColor: dividerColor,
                                        },
                                    }}
                                >
                                    <Chip variant="outlined" label="Popular" sx={{ backgroundColor: dividerColor, color: textColor, mt: 1, mb: 1 }} />
                                </Divider>
                            </Fragment>
                        ) : (
                            <ListItem disablePadding key={index} onMouseOver={() => handleHover(index)} id={`menu-item-${index}`} className={styles.mainLink}>
                                {!item.submenu ? (
                                    <Link href={item.link} passHref>
                                        <ListItemButton className={styles["dynamic-padding"]} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <ListItemIcon>
                                                <div className={classNames(item.icon, styles["menu-fontsize"])} style={{ color: textColor, marginLeft: "4px" }} />
                                            </ListItemIcon>
                                            <ListItemText sx={{ margin: 0 }}>
                                                <Typography variant="h5userMenu"> {item.text} </Typography>
                                            </ListItemText>
                                        </ListItemButton>
                                    </Link>
                                ) : !isnotMobile ? (
                                    <ListItemButton className={styles["dynamic-padding"]} onClick={(e: React.KeyboardEvent | React.MouseEvent) => e.stopPropagation()} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <ListItemIcon>
                                            <div className={classNames(item.icon, styles["menu-fontsize"])} style={{ color: textColor, marginLeft: "4px" }} />
                                        </ListItemIcon>
                                        <ListItemText sx={{ margin: 0 }}>
                                            <Typography variant="h5userMenu">{item.text}</Typography>
                                        </ListItemText>
                                        <ChevronRightIcon className={styles["menu-fontsize"]} sx={{ color: textColor }} />
                                    </ListItemButton>
                                ) : selectedItem && menuList[selectedItem].submenu ? (
                                    <Link href={menuList[selectedItem].submenu[0].href} passHref>
                                        {/* the following code is the same as the one below, but enclosed in a link */}
                                        <ListItemButton className={styles["dynamic-padding"]} onClick={(e: React.KeyboardEvent | React.MouseEvent) => e.stopPropagation()} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <ListItemIcon>
                                                <div className={classNames(item.icon, styles["menu-fontsize"])} style={{ color: textColor, marginLeft: "4px" }} />
                                            </ListItemIcon>
                                            <ListItemText sx={{ margin: 0 }}>
                                                <Typography variant="h5userMenu">{item.text}</Typography>
                                            </ListItemText>
                                            <ChevronRightIcon className={styles["menu-fontsize"]} sx={{ color: textColor }} />
                                        </ListItemButton>
                                    </Link>
                                ) : (
                                    <>
                                        {/* Popular menu items */}
                                        <ListItemButton className={styles["dynamic-padding"]} onClick={(e: React.KeyboardEvent | React.MouseEvent) => e.stopPropagation()} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <ListItemIcon>
                                                <div className={classNames(item.icon, styles["menu-fontsize"])} style={{ color: textColor, marginLeft: "4px" }} />
                                            </ListItemIcon>
                                            <ListItemText sx={{ margin: 0 }}>
                                                <Typography variant="h5userMenu">{item.text}</Typography>
                                            </ListItemText>
                                            <ChevronRightIcon className={styles["menu-fontsize"]} sx={{ color: textColor }} />
                                        </ListItemButton>
                                    </>
                                )}
                            </ListItem>
                        )
                    ) : (
                        <ListItem disablePadding key={index} onMouseOver={() => handleHover(index, true)} id={`small-menu-item-${index}`}>
                            {item.text === "Popular" ? null : !item.submenu ? (
                                <Link href={item.link} passHref>
                                    <ListItemButton className={styles["dynamic-padding"]} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <ListItemIcon>
                                            <div className={classNames(item.icon, styles["sidemenu-fontsize"])} style={{ color: textColor }} />
                                        </ListItemIcon>
                                    </ListItemButton>
                                </Link>
                            ) : (
                                <>
                                    {selectedItem && menuList[selectedItem].submenu ? (
                                        <Link href={menuList[selectedItem].submenu[0].href} passHref>
                                            <ListItemButton className={styles["dynamic-padding"]} onClick={(e: React.KeyboardEvent | React.MouseEvent) => e.stopPropagation()} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <ListItemIcon>
                                                    <div className={classNames(item.icon, styles["sidemenu-fontsize"])} style={{ color: textColor }} />
                                                </ListItemIcon>
                                            </ListItemButton>
                                        </Link>
                                    ) : (
                                        <ListItemButton className={styles["dynamic-padding"]} onClick={(e: React.KeyboardEvent | React.MouseEvent) => e.stopPropagation()} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <ListItemIcon>
                                                <div className={classNames(item.icon, styles["sidemenu-fontsize"])} style={{ color: textColor }} />
                                            </ListItemIcon>
                                        </ListItemButton>
                                    )}
                                </>
                            )}
                        </ListItem>
                    )
                )}
            </List>
            {selectedItem !== undefined &&
                menuList[selectedItem].submenu !== undefined &&
                createPortal(
                    <MenuPopup links={menuList[selectedItem].submenu} index={selectedItem} parentId={minMenuHovered ? "small-menu-item-" : "menu-item-"} />,
                    minMenuHovered ? document.getElementById("sideMenu") : document.getElementById("menu") || document.body
                )}
        </>
    );
};
