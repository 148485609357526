import { Box, CircularProgress, Tooltip } from '@mui/material';
import { FC, useEffect } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import styles from "./NewTickerTape.module.scss";
import { getTimeDifference } from "@/lib/utils";
import InfoIcon from "@mui/icons-material/Info";
import dayjs from 'dayjs';
import { useTickerWebSocket } from 'src/hooks/useTickerWebSocket';

interface PropsTypes {
    tickerStock: any;
    handleTickerClick: any;
    index: number;
    tickerTapeIndex: number;
    tickerKey: number;
}

const TickerStock: FC<PropsTypes> = ({ tickerStock, handleTickerClick, index, tickerTapeIndex, tickerKey }) => {
    const { instrument, isLoading } = useTickerWebSocket(tickerStock, tickerTapeIndex);
    return (
        <Box
            className={styles.tickertapeElement}
            sx={{
                width: "max-content",
                backgroundColor: "transparent",
            }}
            onClick={(e) => handleTickerClick(e, instrument)}
        >
            {instrument[0] !== undefined && (
                <Box className={styles.slideItem}>
                    <p>
                        {instrument[1]["name"] && `${instrument[1]?.["name"]?.slice(0, instrument[1]?.name?.length > 15 ? 15 : instrument[1]?.name?.length)}${instrument[1]?.name?.length > 15 ? "..." : ""}`}
                        &nbsp;
                    </p>
                    <p>{(instrument[1].price ?? instrument[1].lastPrice)?.toFixed(2)}</p>
                    <div className={styles.priceVariation}>
                        {(instrument[1]["change"] ?? instrument[1]["lastChange"]) < 0 ? (
                            <ArrowDownwardIcon className={(instrument[1]["change"] ?? instrument[1]["lastChange"]) < 0 ? "negative" : "positive"} sx={{ fontSize: "14px" }} />
                        ) : (
                            <ArrowUpwardIcon className={(instrument[1]["change"] ?? instrument[1]["lastChange"]) < 0 ? "negative" : "positive"} sx={{ fontSize: "14px" }} />
                        )}
                        <p className={(instrument[1]["change"] ?? instrument[1]["lastChange"]) < 0 ? "negative" : "positive"} style={{ display: "flex", alignItems: "center" }}>
                            &nbsp;{
                                isLoading && (instrument[1]?.["lastChange"] === instrument[1]?.["lastPrice"] || instrument[1]?.lastChange === 0)
                                    ? <CircularProgress size={15} />
                                    : (instrument[1]["percentage"]
                                        ? instrument[1]["percentage"].toFixed(2) + "%"
                                        : (instrument[1]?.["lastChange"] === instrument[1]?.["lastPrice"] || instrument[1]?.lastChange === 0
                                            ? "N/A"
                                            : ((instrument[1]?.["lastChange"] / instrument[1]?.["lastPrice"]) * 100)?.toFixed(2) + "%"))
                            }
                        </p>
                        {(getTimeDifference(instrument[1].timestamp)[1] < 0 || getTimeDifference(instrument[1].timestamp)[1] > 20 || getTimeDifference(instrument[1].timestamp)[0] !== 0) && (
                            <Tooltip arrow title={`Last updated: ${dayjs(instrument[1].timestamp).format("DD MMM HH:mm")}`}>
                                <InfoIcon sx={{ fontSize: "14px", marginLeft: 0.5 }} />
                            </Tooltip>
                        )}
                    </div>
                </Box>
            )}
        </Box>
    );
};

export default TickerStock;