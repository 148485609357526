import { getTopBarValues } from '@/controllers/instruments';
import { getStockInfoOnClient } from '@/controllers/stock';
import { tickerTapeState, userState } from '@/lib/store';
import { Box, } from '@mui/material';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Ticker from 'react-ticker';
import { useRecoilState, useRecoilValue } from 'recoil';
import styles from "./NewTickerTape.module.scss";
import TickerStock from './TickerStock';
import TickertapePopover from '../tickertape-popover/TickertapePopover';
import { Context } from '@/lib/Context';
import { unsuscribeFromWS } from '@/lib/utils';

interface Instrument {
    name: string;
    currency: string;
    price: number;
    lastPrice: number;
    lastChange: number;
    timestamp: number;
    visibility: boolean;
    order?: number;
}

const liveInstruments: { [identifier: string]: { name: string; currency?: string; }; } = {
    "INDU:IND": { name: "DJIA" },
    "SPX:IND": { name: "S&P 500" },
    "CCMP:IND": { name: "Nasdaq Comp" },
    "US100:IND": { name: "Nasdaq 100" },
    "CL1:COM": { name: "OIL (WTI)", currency: "COM" },
    "SX5E:IND": { name: "ESTOXX50", currency: "COM" },
};

const NewTickerTape: React.FC = ({ }) => {
    const [pause, setPause] = useState<boolean>(false);
    const [instrumentClicked, setInstrumentClicked] = useState(null);
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const { multiWebSocket, ortexWebSocket, obookWebSocket } = useContext(Context);
    const tickerKey = useRef(0);

    const [tickerStocks, setTickerStocks] = useRecoilState(tickerTapeState);
    const tickerStocksLengthRef = useRef(0);
    const user = useRecoilValue(userState);

    const instrumentsList = useMemo(() => {
        if (tickerStocks) {
            const newList = Object.entries(tickerStocks)
                .filter(([key]) => key !== "undefined")
                .sort(([keyA, instrumentA], [keyB, instrumentB]) => {
                    const orderA = instrumentA.order !== undefined ? instrumentA.order : Infinity;
                    const orderB = instrumentB.order !== undefined ? instrumentB.order : Infinity;
                    return orderA - orderB;
                });
            tickerKey.current++;
            return newList;

        } else {
            tickerKey.current = 0;
            return [];
        }
    }, [tickerStocks]);

    const getLiveInstruments = () => {
        getTopBarValues()
            .then((values) => {
                const newInstruments: any = {};
                Object.keys(values)
                    .filter((key) => key !== "obfuscated" && liveInstruments[key])
                    .forEach((key) => {
                        newInstruments[key] = {
                            name: liveInstruments[key].name,
                            currency: liveInstruments[key].currency,
                            price: values[key].price,
                            lastPrice: values[key].price,
                            lastChange: values[key].price,
                            timestamp: values[key].ts,
                            visibility: true,
                        };
                    });

                tickerStocksLengthRef.current = Object.keys(newInstruments).length;
                setTickerStocks((prevState) => ({ ...prevState, ...newInstruments }));

            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        const savedTickerStocks = user?.settings?.module_settings["tickerTape->tickertape-settings"] ? Object.entries(user?.settings?.module_settings["tickerTape->tickertape-settings"]) : (Object.entries(tickerStocks) ?? []);

        tickerStocksLengthRef.current = (savedTickerStocks.length);
        if (savedTickerStocks.length > 0) {
            savedTickerStocks.forEach(([identifier, instrument]: any) => {
                if (!liveInstruments[identifier] && identifier !== "BTCUSD" && identifier !== "undefined" && identifier !== "WTIUSDBCOMP" && identifier !== "NDX:IND") {
                    getStockInfoOnClient(instrument.stock_id)
                        .then((data) => {
                            setTickerStocks((prevState) => ({
                                ...prevState,
                                [identifier]: {
                                    ...instrument,
                                    isin: data.isin,
                                    price: data.last_close,
                                    lastPrice: data.last_close,
                                    lastChange: 0,
                                },
                            }));
                        })
                        .catch((err) => console.log(err));
                }
            });
        } else {
            getLiveInstruments();
        }
    }, [user?.settings?.module_settings["tickerTape->tickertape-settings"],]);

    useEffect(() => {
        try {
            const prevStocks = tickerStocksLengthRef.current;
            const currentStocks = tickerStocks;

            if (prevStocks === Object.keys(currentStocks).length) return;

            const removedTickers = Object.keys(prevStocks).filter((ticker) => !currentStocks.hasOwnProperty(ticker));

            removedTickers.forEach((ticker) => {
                const stock = prevStocks[ticker];

                if (!stock || stock.name === "Bitcoin") return;

                unsuscribeFromWS(stock, obookWebSocket, multiWebSocket, ortexWebSocket);
            });

            tickerStocksLengthRef.current = Object.keys(tickerStocks).length;
        } catch (error) {
            console.log("Error when unsubscribing from WS", error);
        }
    }, [tickerStocks]);

    const handleResumePlaying = () => {
        setInstrumentClicked(null);
        setPause(false);
        setAnchorEl(null);
    };

    const handleTickerClick = (e, instrument) => {
        setInstrumentClicked(instrument);
        setAnchorEl(e.currentTarget);
        setPause(true);
    };


    return (
        <div className={styles.tickerwrap}>
            {instrumentsList.length > 0 && (
                <Ticker key={`tickertape-${tickerKey.current}`} mode="chain" move={!pause} speed={3} offset={"20%"} >
                    {({ index }) => (
                        <>
                            <Box className={styles.tickertape} sx={{ width: "100%", display: "flex", alignItems: "flex-start" }}>
                                {instrumentsList
                                    .filter((instrument) => instrument[1].visibility && !instrument[1].removed)
                                    .map((instrument, i) => {
                                        return (
                                            <TickerStock tickerStock={instrument} tickerKey={tickerKey.current} tickerTapeIndex={index} index={i} handleTickerClick={handleTickerClick} key={`${tickerKey}-${instrument[0]}-${index}-${i}`}
                                            />
                                        );
                                    })}
                            </Box>
                        </>
                    )}
                </Ticker>
            )}
            <TickertapePopover instrument={instrumentClicked} anchorEl={anchorEl} handleClose={handleResumePlaying} />
        </div>
    );
};

export default NewTickerTape;




